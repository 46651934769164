import styled from "styled-components"
import { Button } from "primereact/button"

import { useStore } from "state/store"
import { useCallback, useEffect, useRef, useState } from "react"
import { usePostHog } from "posthog-js/react"
import { useNavigate } from "react-router-dom"

import { updateUser, checkUser } from "api/auth"
import { setCookie, eraseCookieForLogout } from "utils"
import PageTransition from "components/PageTransition"
import PageHead from "./components/PageHead"
import { MiddleContainer } from "components/Containers"
import Navbar from "components/Navbar"
import Spiner from "components/Spiner"
import "./index.css"

export default function Eula() {
  const posthog = usePostHog()
  const navigate = useNavigate()
  const eulaContainerRef = useRef(null)
  const currentUser = useStore((state) => state.currentUser)
  const setCurrentUser = useStore((state) => state.setCurrentUser)
  const setAcceptedEula = useStore((state) => state.setAcceptedEula)
  const resetStore = useStore((state) => state.resetStore)
  const userStub = useStore((state) => state.userStub)
  const [rawHtml, setRawHtml] = useState("")
  const [loading, setLoading] = useState(true)
  const [endOfScroll, setEndOfScroll] = useState(false)

  const handleDecline = useCallback(() => {
    setAcceptedEula(false)
    eraseCookieForLogout()
    setCurrentUser(null)
    navigate("/")
    resetStore()
  }, [setAcceptedEula, navigate, resetStore, setCurrentUser])

  const handleScroll = () => {
    const container = eulaContainerRef.current
    if (container) {
      if (
        container.scrollHeight - container.clientHeight - 20 <=
        container.scrollTop
      ) {
        setEndOfScroll(true)
      }
    }
  }

  const handleAccept = useCallback(async () => {
    if (userStub?.refresh_token) {
      const { refresh_token, access_token, checked, email } = userStub
      if (access_token) {
        posthog.capture("Logged in")
        if (checked) {
          setCookie("gac_refresh_token", refresh_token, 365)
        }
        setCookie("gac_access_token", access_token, 1)
        setCookie("gac_user_email", email, 1)
      }
    }
    const response = await updateUser({
      id: currentUser?.id || userStub?.id,
      accepted_eula: true,
    })
    if (response?.status === 200) {
      checkUser().then((user) => {
        if (user) {
          setCurrentUser(user)
          setAcceptedEula(true)
          navigate("/")
        } else {
          eraseCookieForLogout()
          setAcceptedEula(true)
        }
      })
    } else {
      setAcceptedEula(false)
    }
  }, [currentUser?.id, setCurrentUser, setAcceptedEula, userStub, navigate])

  useEffect(() => {
    // fetch eula
    fetch("https://arion-eula.s3.us-east-1.amazonaws.com/arion_eula.txt", {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        "Cache-Control": "no-cache",
      },
    })
      .then((response) => response.text())
      .then((data) => {
        setRawHtml(data)
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (eulaContainerRef.current) {
      eulaContainerRef.current.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (eulaContainerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        eulaContainerRef.current.removeEventListener("scroll", handleScroll)
      }
    }
  }, [eulaContainerRef, loading])

  if (loading) {
    return (
      <div className="flex justify-content-center w-full pt-8">
        <Spiner />
      </div>
    )
  }

  return (
    <Con ref={eulaContainerRef}>
      {currentUser?.accepted_eula && <Navbar />}
      <PageTransition>
        <Holder>
          <StyledPageHead>
            <Title className="mb-3">ARION End User License Agreement</Title>
          </StyledPageHead>
          <MiddleContainer>
            <div
              className="eula-content pb-8"
              dangerouslySetInnerHTML={{ __html: rawHtml }}
            ></div>
            {!currentUser?.accepted_eula && currentUser?.id && (
              <div className="eula-footer">
                <Button
                  label="Decline"
                  className="p-button-secondary"
                  outlined
                  onClick={handleDecline}
                  disabled={!endOfScroll}
                />
                <Button
                  label="Accept"
                  className="p-button-primary"
                  onClick={handleAccept}
                  disabled={!endOfScroll}
                />
              </div>
            )}
          </MiddleContainer>
        </Holder>
      </PageTransition>
    </Con>
  )
}

const Con = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
`

const Holder = styled.div`
  width: 100%;
  min-height: 120vh;
`

const Title = styled.div`
  font-size: 2em;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.25;
  letter-spacing: 0.01em;
`

const StyledPageHead = styled(PageHead)`
  padding: 12em 0 1em 0;
`
