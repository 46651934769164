import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { useFormik } from "formik"
import { usePostHog } from "posthog-js/react"
import { InputText } from "primereact/inputtext"
import { Checkbox } from "primereact/checkbox"
import { Message } from "primereact/message"
import { Button } from "primereact/button"

import { setCookie } from "utils"
import { checkUser, doSignIn } from "api/auth"
import { useStore } from "state/store"
import { PrivacyPolicyRoute } from "routes"
import ResetPasswordRequestDialog from "components/ResetPasswordRequestDialog"
import LoginBg from "assets/images/January2018_Nampo_GoogleEarth.jpg"
import LogoIcon from "assets/images/arion-logo-blue.svg"

const SignIn = () => {
  const navigate = useNavigate()

  const posthog = usePostHog()

  const setAcceptedEula = useStore((state) => state.setAcceptedEula)

  const [checked, setChecked] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [showResetPassword, setShowResetPassword] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [emailError, setEmailError] = useState()
  const [passwordError, setPasswordError] = useState()

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (data) => {
      let valid = 0

      if (!data.email) {
        setEmailError("Email is required")
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        setEmailError("Invalid email address. E.g. example@email.com")
      } else {
        setEmailError("")
        valid++
      }
      if (!data.password) {
        setPasswordError("Password is requrired")
      } else {
        setPasswordError("")
        valid++
      }

      if (valid < 2) {
        return
      }

      setIsProcessing(true)

      doSignIn(data)
        .then(
          async ({
            token: { access_token, refresh_token },
            accepted_eula,
            id,
          }) => {
            setAcceptedEula(accepted_eula)
            posthog.identify(data.email, { email: data.email })
            posthog.capture("Logged in")
            if (checked) {
              //store data for 365 days
              setCookie("gac_refresh_token", refresh_token, 365)
            }
            //store data for 1 day
            setCookie("gac_access_token", access_token, 1)
            setCookie("gac_user_email", data.email, 1)

            if (access_token) {
              checkUser().then((user) => {
                if (user) {
                  if (user.accepted_eula) {
                    navigate("/")
                  } else {
                    navigate("/eula")
                  }
                }
              })
            }
          },
        )
        .catch((err) => {
          setErrorMessage(err.message)
        })
        .finally(() => {
          // formik.resetForm()
          setIsProcessing(false)
        })
    },
  })

  const openResetPasswordHide = (e) => {
    e.preventDefault()
    setShowResetPassword(true)
  }

  const closeResetPassword = () => {
    setShowResetPassword(false)
  }

  return (
    <Holder>
      <div className="col-12 md:col-4 p-0">
        <BgHolder />
      </div>
      <Container className="col-12 md:col-8 absolute md:relative md:flex md:align-items-center">
        <div className="w-full flex flex-column">
          <BrandHead>
            <Link to="/">
              <Brand alt="logo" src={LogoIcon} />
            </Link>
          </BrandHead>
          <FormContainer className="mt-4">
            <h1 className="m-0">Log In</h1>
            <form onSubmit={formik.handleSubmit}>
              {errorMessage && (
                <Message
                  severity="error"
                  className="w-full mt-3"
                  text={errorMessage}
                />
              )}
              <div className="mt-4">
                <span className="p-float-label">
                  <InputTextStyled
                    id="email"
                    name="email"
                    className="w-full"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    autoFocus
                  />
                  <label htmlFor="email">Email</label>
                </span>
                {emailError && (
                  <span className="font-medium p-error text-xs">
                    {emailError}
                  </span>
                )}

                <span className="p-float-label mt-4">
                  <InputTextStyled
                    className="w-full"
                    type="password"
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                  <label htmlFor="password">Password</label>
                </span>
                {passwordError && (
                  <span className="p-error text-xs">
                    {passwordError && passwordError}
                  </span>
                )}

                <div className="flex mt-3 justify-content-between">
                  <div className="field-checkbox">
                    <Checkbox
                      inputId="remember_me"
                      checked={checked}
                      onChange={(e) => setChecked(e.checked)}
                    />
                    <label htmlFor="remember_me">Remember Me</label>
                  </div>
                  <span
                    className="forgot_password"
                    onClick={openResetPasswordHide}
                  >
                    Forgot password?
                  </span>
                </div>
                <Button
                  type="submit"
                  color="primary"
                  className="w-full justify-content-center mt-3 gap-2"
                  disabled={isProcessing}
                  loading={isProcessing}
                >
                  Log In
                </Button>
              </div>
              <div className="flex gap-4 mt-3 justify-content-center align-items-center mb-5">
                <StyledLink
                  to={PrivacyPolicyRoute.path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </StyledLink>
                <StyledLink
                  to="/eula"
                  target="_parent"
                  rel="noopener noreferrer"
                >
                  EULA
                </StyledLink>
                <StyledLink
                  to="/about-us"
                  target="_parent"
                  rel="noopener noreferrer"
                >
                  About
                </StyledLink>
                <StyledLink
                  to="https://arion.launchnotes.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Changelog
                </StyledLink>
              </div>
            </form>
          </FormContainer>
        </div>
      </Container>
      <ResetPasswordRequestDialog
        visible={showResetPassword}
        onHide={closeResetPassword}
      />
    </Holder>
  )
}

export default SignIn

const Holder = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
`

const BgHolder = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url(${LoginBg});
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  background-position: center;
`

const BrandHead = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
`

const Brand = styled.img`
  width: 14em;
`

const FormContainer = styled.div`
  h1 {
    font-weight: 700;
    font-size: 1.8em;
    line-height: 2.2em;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
    color: #1d1d1b;
  }
  .forgot_password {
    font-weight: 700;
    font-size: 0.9em;
    line-height: 1em;
    color: #f75151;
    cursor: pointer;
  }

  button {
    background: #f75151;
    border-radius: 4px;
    font-weight: 700;
    font-size: 1em;
    line-height: 1.2em;
    color: #ffffff;
    padding: 1em 2.3em;
  }

  h2 {
    font-weight: 700;
    font-size: 1.1em;
    line-height: 1.1em;
    color: #000000;
  }

  a {
    color: #0062d5;
  }

  .p-checkbox .p-checkbox-box {
    width: 18px;
    height: 18px;
    border: 1px solid #dddddd;
    border-radius: 4px;
  }
  .p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
    top: 7px;
    left: 2px;
  }
`
const Container = styled.div`
  width: 70%;
  max-width: 450px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.8);
  padding: 0 2em;
  @media (max-width: 500px) {
    width: 90%;
    max-width: auto;
  }
  @media (max-width: 768px) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const InputTextStyled = styled(InputText)`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 0.5em;
  padding: 0.9em;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.75em;
  color: #000000;
`

const StyledLink = styled(Link)`
  position: relative;
  padding: 0;
  font-size: 0.875em;
  color: #0062d4;
  font-weight: 400;
  &:after {
    content: "";
    position: absolute;
    right: -0.9em;
    top: calc(50% - 0.15em);
    width: 0.3em;
    height: 0.3em;
    background: #8a8a8a;
    border-radius: 50%;
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
`
