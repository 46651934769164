import moment from "moment"

function compareStrings(a, b) {
  // Assuming you want case-insensitive comparison
  a = a.toLowerCase()
  b = b.toLowerCase()

  return a < b ? -1 : a > b ? 1 : 0
}

/**
 * @param {Array} array
 * @param {String} key
 */
function sortAlphabetically(array, key) {
  array.sort(function (a, b) {
    return compareStrings(a[key], b[key])
  })
}

function centroid(pts) {
  let nPts = pts.length
  let x = 0
  let y = 0
  let f
  let j = nPts - 1
  let p1
  let p2

  for (let i = 0; i < nPts; j = i++) {
    p1 = pts[i]
    p2 = pts[j]
    f = p1[0] * p2[1] - p2[0] * p1[1]
    x += (p1[0] + p2[0]) * f
    y += (p1[1] + p2[1]) * f
  }

  //Area
  let area = 0
  j = nPts - 1
  for (let i = 0; i < nPts; j = i++) {
    p1 = pts[i]
    p2 = pts[j]
    area += p1[0] * p2[1]
    area -= p1[1] * p2[0]
  }
  area /= 2

  f = area * 6

  return [x / f, y / f]
}

//Inside point in polygon
function insidePointInPolygon(point, polygon) {
  if (polygon[0] !== polygon[polygon.length - 1]) {
    polygon[polygon.length] = polygon[0]
  }
  let j = 0
  let isInside = false
  let x = point[1]
  let y = point[0]
  let n = polygon.length
  for (let i = 0; i < n; i++) {
    j++
    if (j === n) {
      j = 0
    }
    if (
      (polygon[i][0] < y && polygon[j][0] >= y) ||
      (polygon[j][0] < y && polygon[i][0] >= y)
    ) {
      if (
        polygon[i][1] +
          ((y - polygon[i][0]) / (polygon[j][0] - polygon[i][0])) *
            (polygon[j][1] - polygon[i][1]) <
        x
      ) {
        isInside = !isInside
      }
    }
  }
  return isInside
}

const getQueryString = (search = window.location.search, name) => {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
  let r = search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

/**
 * @param {Integer} roleIndex
 */
function getRoleName(roleIndex) {
  return parseInt(roleIndex) === 0 ? "Administrator" : "User"
}

const checkPermissions = (userPermissions = [], allowedPermissions = []) => {
  if (allowedPermissions.length === 0 || userPermissions.includes("*")) {
    return true
  }

  return userPermissions.some((permission) =>
    allowedPermissions.includes(permission),
  )
}

const getValueForHumans = (val) => {
  return !val || val === "0" || val === 0 || val === "None" || val === "Unknown"
    ? "N/A"
    : val
}

const setCookie = (name, value, days) => {
  var expires = ""
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = "; expires=" + date.toUTCString()
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/"
}
const getCookie = (name) => {
  var nameEQ = name + "="
  var ca = document.cookie.split(";")
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === " ") c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}
const eraseCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"
}

const eraseCookieForLogout = () => {
  eraseCookie("gac_access_token")
  eraseCookie("gac_refresh_token")
  eraseCookie("gac_user_email")
  eraseCookie("interest_db_filter")
}

const formatDateString = (date) => {
  let result = "N/A"
  if (date) {
    result = moment(date).utc().format("MM/DD/YYYY")
  }
  return result
}

const convertTextToSlug = (text) => {
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "")
}

const getLatLng = (val = null) => {
  if (!val) return "N/A"
  const parts = val.split("POINT (")
  if (parts.length > 1) {
    let latLng = parts[1]
    latLng = latLng.replace(")", "")
    let partsLatLng = latLng.split(" ")
    partsLatLng = partsLatLng.reverse()
    latLng = partsLatLng.join(", ")
    return latLng
  }

  return "N/A"
}

const sortFlags = (arr) => {
  const sorted = arr
    .filter((item) => item.value !== "0")
    .sort((a, b) => a.label.localeCompare(b.label))
  const zeros = arr.filter((item) => item.value === "0")
  return [...zeros, ...sorted]
}

const strToFileSize = (str) => {
  const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  let size = parseInt(str)
  let unit = 0
  while (size >= 1024) {
    size /= 1024
    unit++
  }
  return `${size.toFixed(0)} ${units[unit]}`
}

export {
  sortAlphabetically,
  centroid,
  insidePointInPolygon,
  getQueryString,
  getRoleName,
  checkPermissions,
  getValueForHumans,
  setCookie,
  getCookie,
  eraseCookie,
  formatDateString,
  convertTextToSlug,
  getLatLng,
  sortFlags,
  eraseCookieForLogout,
  strToFileSize,
}

export const isElementInViewport = (id) => {
  const el = document.getElementById(id)

  if (el) {
    const rect = el.getBoundingClientRect()
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  return false
}
